import React, { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Space,
  Switch,
  Divider,
  Select,
  Card,
  notification,
  Collapse,
  Radio,
  Flex,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { get, toPairs, cloneDeep, omit } from 'lodash';
import { useSelector } from 'react-redux';
import { useSsps } from '../../hooks/useSsps';

const { Panel } = Collapse;

const defaultDspFields = {
  partnerId: '',
  name: '',
  host: '',
  port: 80,
  path: '',
  useBanner: false,
  useVideo: false,
  callNurls: false,
  mobileWeb: false,
  inApp: false,
  desktop: false,
  instlOnly: 0,
  minTmax: 0,
  reqUserId: 0,
  useAdapter: 0,
  dailySpendLimit: 0,
  totalSpendLimit: 0,
  maxBidfloor: 0,
  onlyStoredApps: 0,
  onlyMatchedUsers: 0,
  pornFilter: 0,
  mismatchIpFilter: 0,
  mismatchBundlesFilter: 0,
  lowSecondPrice: 0,
  adapterLearningPercent: 0,
  PMB: 0,
  PMW: 0,
  FB: 0,
  FW: 0,
  secure: false,
  margin: 0,
  limitQps: 0,
  maxSupplyChainNodes: 0,
  supplyChainCompleted: false,
  isActive: false,
  includeExtraMargin: false,
  extraMargin: 0,
  note: '',
  custom: null,
  useGzip: false,
  dnsResolve: false,
  optimizer: true,

  // disabled fields
  useNative: false,
  videoStartTracker: false,

  // removed fields
  isBlocked: false,
  usePop: false,
};

const getEndpointUrl = (dspFields) => {
  if (dspFields.endpointUrl) {
    return dspFields.endpointUrl;
  }

  if (dspFields.host && dspFields.port && dspFields.path) {
    return [
      dspFields.host.replace('https://', '').replace('http://', ''),
      dspFields.port === 80 ? '' : `:${dspFields.port}`,
      dspFields.path,
    ].join('');
  }

  return '';
};

export default function UpsertDspForm(props) {
  const ssps = useSsps();
  const [form] = Form.useForm();

  const [dspFields, setDspFields] = useState({});
  const [endpointUrlType, setEndpointUrlType] = useState(
    (get(props, 'dsp.host') || '').includes('internal') ? 'internal' : 'external'
  );

  const userInfo = useSelector((state) => state.login);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const dspFields = {
      // eslint-disable-next-line react/prop-types
      ...(props.dsp || defaultDspFields),
      // eslint-disable-next-line react/prop-types
      endpointUrl: getEndpointUrl(props.dsp || defaultDspFields),
      protocol: (get(props, 'dsp.host') || '').replace(/:\/\/.+/, '://'),
      // eslint-disable-next-line react/prop-types
      authUsername: (props.dsp && get(JSON.parse(props.dsp.custom || '{}'), 'authorization.username')) || null,
      // eslint-disable-next-line react/prop-types
      authPassword: (props.dsp && get(JSON.parse(props.dsp.custom || '{}'), 'authorization.password')) || null,
      // eslint-disable-next-line react/prop-types
      headers: (props.dsp && get(JSON.parse(props.dsp.custom || '{}'), 'headers')) || [],
      // eslint-disable-next-line react/prop-types
      bidRequestFields: toPairs((props.dsp && get(JSON.parse(props.dsp.custom || '{}'), 'bidRequestFields')) || {}).map(
        ([key, value]) => ({ key, value })
      ),
    };

    setDspFields(dspFields);
    form.setFieldsValue(dspFields);
  }, [props]);

  const onFinish = (values) => {
    if (values.margin && values.extraMargin) {
      notification.error({
        message: 'Please, use margin OR revenue share',
        description: `margin: ${values.margin}, revenue share: ${values.extraMargin}`,
        duration: 0,
      });

      return;
    }

    const canChangeCustom = userInfo && ['admin', 'localAdmin'].includes(userInfo.role);

    // eslint-disable-next-line react/prop-types
    props.onSubmit(
      omit(
        cloneDeep({
          ...dspFields,
          ...values,
          endpointUrl: `${values.protocol || ''}${values.endpointUrl}`,
          custom: canChangeCustom
            ? JSON.stringify({
                authorization: {
                  username: values.authUsername || null,
                  password: values.authPassword || null,
                },
                headers: values.headers || [],
                bidRequestFields: (values.bidRequestFields || []).reduce(
                  (acc, cur) => ({ ...acc, [cur.key]: cur.value }),
                  {}
                ),
              })
            : // eslint-disable-next-line react/prop-types
              props?.dsp?.custom || '{}',
        }),
        ['authUsername', 'authPassword', 'headers', 'bidRequestFields']
      )
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleEndpointTypeChange = (e) => {
    setEndpointUrlType(e.target.value);
  };

  return (
    <div>
      <Divider />

      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* both panels should be open because if panel #2 will be closed by default then it's settings will be wiped */}
        <Collapse bordered={false} defaultActiveKey={['1', '2']} style={{ backgroundColor: 'transparent' }}>
          <Panel header="General" key="1">
            <Form.Item label="Active:" valuePropName="checked" name="isActive">
              <Switch />
            </Form.Item>

            <Divider dashed />

            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Endpoint Url">
              <Flex>
                <Radio.Group
                  style={{ display: 'flex', marginRight: '10px' }}
                  value={endpointUrlType}
                  onChange={handleEndpointTypeChange}
                >
                  <Radio.Button value="external">External</Radio.Button>
                  <Radio.Button value="internal">Internal</Radio.Button>
                </Radio.Group>

                <Form.Item name="endpointUrl" rules={[{ required: true }]} style={{ width: '100%' }}>
                  {endpointUrlType === 'internal' && (
                    <Select
                      placeholder="Select an SSP"
                      options={ssps.map((x) => ({ label: `[${x.id}] ${x.name}`, value: `internal://${x.id}/` }))}
                      showSearch
                      filterOption={(input, option) =>
                        ((option && option.label) || '').toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  )}

                  {endpointUrlType === 'external' && (
                    <Input
                      addonBefore={
                        <Form.Item noStyle name="protocol">
                          <Select defaultValue="http://" name="protocol">
                            <Select.Option value="http://">http://</Select.Option>
                            <Select.Option value="https://">https://</Select.Option>
                          </Select>
                        </Form.Item>
                      }
                    />
                  )}
                </Form.Item>
              </Flex>
            </Form.Item>

            <Space>
              <Form.Item name="useBanner" valuePropName="checked">
                <Checkbox>useBanner</Checkbox>
              </Form.Item>

              <Form.Item name="useNative" valuePropName="checked">
                <Checkbox disabled>useNative</Checkbox>
              </Form.Item>

              <Form.Item name="useVideo" valuePropName="checked">
                <Checkbox>useVideo</Checkbox>
              </Form.Item>
            </Space>

            <br />

            <Space>
              <Form.Item name="mobileWeb" valuePropName="checked">
                <Checkbox>mobileWeb</Checkbox>
              </Form.Item>

              <Form.Item name="inApp" valuePropName="checked">
                <Checkbox>inApp</Checkbox>
              </Form.Item>

              <Form.Item name="desktop" valuePropName="checked">
                <Checkbox>desktop</Checkbox>
              </Form.Item>
            </Space>

            <br />

            <Space>
              <Form.Item name="secure" valuePropName="checked">
                <Checkbox>secure</Checkbox>
              </Form.Item>

              <Form.Item name="pornFilter" valuePropName="checked">
                <Checkbox>pornFilter</Checkbox>
              </Form.Item>
            </Space>

            <br />

            <Form.Item name="videoStartTracker" valuePropName="checked">
              <Checkbox disabled>videoStartTracker</Checkbox>
            </Form.Item>

            <Form.Item name="useGzip" valuePropName="checked">
              <Checkbox>Send bid requests only in Gzip?</Checkbox>
            </Form.Item>

            <Form.Item name="dnsResolve" valuePropName="checked">
              <Checkbox disabled={true}>Resolve DNS? (can be enabled per request)</Checkbox>
            </Form.Item>

            <Form.Item name="optimizer" valuePropName="checked">
              <Checkbox disabled value>
                Enable Optimizer?
              </Checkbox>
            </Form.Item>

            <Form.Item label="minTmax" name="minTmax">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="dailySpendLimit" name="dailySpendLimit">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="totalSpendLimit" name="totalSpendLimit">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="maxBidfloor" name="maxBidfloor">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item label="Limit of bid requests per second" name="limitQps">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              label="Supply Chain Max Nodes"
              name="maxSupplyChainNodes"
              help={
                'Specify the maximum number of nodes to retain from the end of the supply chain. Set to 0 to disable this feature.'
              }
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="supplyChainCompleted"
              valuePropName="checked"
              help={'It will be applied to all 100% bid requests'}
            >
              <Checkbox>Mark the Supply Chain as Completed?</Checkbox>
            </Form.Item>

            <Form.Item label="Notes">
              <Input.TextArea name="note" allowClear />
            </Form.Item>

            <Card title="Pricing" style={{ width: '100%', marginBottom: 20 }}>
              <Form.Item
                label="Margin Percent"
                name="margin"
                help="% that will be added to the bidfloor field. Use this field OR revenue share percent"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item
                label="Revenue Share Percent"
                name="extraMargin"
                help="% that will be taken from the extra bid response price. Use this field OR margin percent. If DSP Revenue Share Percent is enabled then we will use SSP DSP Revenue Share Percent"
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Card>
          </Panel>

          <Panel header="Advanced" key="2">
            <Card title="Custom Bid Request Fields" style={{ width: '100%', marginBottom: 20 }}>
              <Form.List name="bidRequestFields">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item {...restField} name={[name, 'key']}>
                          <Input placeholder="Key" />
                        </Form.Item>

                        <Form.Item {...restField} name={[name, 'value']}>
                          <Input placeholder="Value" />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add custom bid request field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Card.Meta description="The 'key' should be a path to the field in bid request that you want to create/update." />
            </Card>

            <Card title="Authorization" style={{ width: '100%', marginBottom: 20 }}>
              <Form.Item label="Username" name="authUsername">
                <Input />
              </Form.Item>

              <Form.Item label="Password" name="authPassword">
                <Input />
              </Form.Item>
            </Card>

            <Card title="Headers" style={{ width: '100%', marginBottom: 20 }}>
              <Form.List name="headers">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item {...restField} name={[name, 'key']}>
                          <Input placeholder="Key" />
                        </Form.Item>

                        <Form.Item {...restField} name={[name, 'value']}>
                          <Input placeholder="Value" />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add header
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Card>
          </Panel>
        </Collapse>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            {dspFields.id ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>

      <Divider />
    </div>
  );
}
