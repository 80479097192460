import React, { useState, type ReactElement } from 'react';
import { uniqBy, sortBy } from 'lodash';
import { Table } from 'antd';
import { Link } from 'react-router-dom';

import NewSspButton from './NewSspButton';
import { Search } from './Search';
import { useSsps } from '../../../hooks/useSsps';
import { PageLayout } from '../../../components/PageLayout';

const getQps = (lastHourStatistics: { sspBidRequest: number }): number =>
  lastHourStatistics ? Math.ceil(lastHourStatistics.sspBidRequest / 3600) : 0;

export function SspsPage(): ReactElement {
  const ssps = useSsps();
  const [searchQuery, setSearchQuery] = useState<string>();

  const sspsToDisplay = searchQuery
    ? ssps.filter(({ id, name }) => `[${id}] ${name}`.toLowerCase().includes(searchQuery.toLowerCase()))
    : ssps;

  return (
    <PageLayout>
      <NewSspButton />

      <Search onSearch={setSearchQuery} />

      <Table
        columns={[
          {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive) => (isActive ? 'Active' : 'Inactive'),
            filters: [
              {
                text: 'Active',
                value: 1,
              },
              {
                text: 'Inactive',
                value: 0,
              },
            ],
            onFilter: (value, { isActive }) => isActive === value,
            width: 100,
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, { id }) => (
              <Link to={`/ssps/${id}`}>
                [{id}] {name}
              </Link>
            ),
            width: 100,
          },
          {
            title: 'Company',
            dataIndex: 'company.firstName',
            key: 'company.firstName',
            // @ts-expect-error TODO
            render: (value, record) => record.company.firstName,
            filters: uniqBy(
              sortBy(
                // @ts-expect-error TODO
                (ssps || []).map((ssp) => ssp.company),
                'firstName'
              ),
              'id'
            ).map((company) => ({
              text: company.firstName,
              value: company.id,
            })),
            // @ts-expect-error TODO
            onFilter: (value, { company }) => company.id === value,
            width: 100,
          },
          {
            title: 'Margin %',
            dataIndex: 'margin',
            key: 'margin',
            render: (value) => value || 0,
            // @ts-expect-error TODO
            sorter: (a, b) => a.margin - b.margin,
            width: 100,
          },
          {
            title: 'Rev. Share %',
            dataIndex: 'revenueSharePercent',
            key: 'revenueSharePercent',
            render: (value) => value || 0,
            // @ts-expect-error TODO
            sorter: (a, b) => a.revenueSharePercent - b.revenueSharePercent,
            width: 100,
          },
          {
            title: 'Swapper',
            dataIndex: 'swapperEnableSwapper',
            key: 'swapperEnableSwapper',
            render: (isActive) => (isActive ? 'Active' : 'Inactive'),
            filters: [
              {
                text: 'Active',
                value: true,
              },
              {
                text: 'Inactive',
                value: false,
              },
            ],
            onFilter: (value, { swapperEnableSwapper }) => swapperEnableSwapper === value,
            width: 100,
          },
          {
            title: 'QPS',
            dataIndex: 'qps',
            key: 'qps',
            // @ts-expect-error TODOL fix this
            render: (value, { lastHourStatistics, limitQps }) =>
              `${getQps(lastHourStatistics) || 0} / ${limitQps || 0}`,
            defaultSortOrder: 'descend',
            // @ts-expect-error TODO
            sorter: (a, b) => getQps(a.lastHourStatistics) - getQps(b.lastHourStatistics),
            width: 100,
          },
          {
            title: 'Spend Today',
            dataIndex: 'dailySpend',
            key: 'dailySpend',
            // @ts-expect-error TODO
            render: (value, record) => `${value} / ${record.dailySpendLimit}`,
            // @ts-expect-error TODO
            sorter: (a, b) => a.dailySpend - b.dailySpend,
            width: 100,
          },
          {
            title: 'Spend Total',
            dataIndex: 'totalSpend',
            key: 'totalSpend',
            // @ts-expect-error TODO
            render: (value, record) => `${value} / ${record.totalSpendLimit}`,
            // @ts-expect-error TODO
            sorter: (a, b) => a.totalSpend - b.totalSpend,
            width: 100,
          },
        ]}
        dataSource={sspsToDisplay}
        pagination={false}
        size="small"
        loading={ssps.length === 0}
        scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
      />
    </PageLayout>
  );
}
