import React, { type ReactElement, useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { Checkbox, Table, Input, type GetProps } from 'antd';
import _ from 'lodash';

import { Status, StatusEnum } from '../../Status';

type Row = Record<string, unknown>;

type Props = {
  aVals: Row[];
  onToggle: (record: Row) => void;
  hiddenColumns?: string[];
  submitToggled: () => void;
};

type SearchProps = GetProps<typeof Input.Search>;

export function PredefinedValues(props: Props): ReactElement {
  const { aVals: data, onToggle, hiddenColumns, submitToggled } = props;

  const [dataToDisplay, setDataToDisplay] = useState(data);

  useEffect(() => {
    setDataToDisplay(data);
  }, [data]);

  const onSearch: SearchProps['onSearch'] = (value) => {
    const keys = _.keys(_.first(data));

    const filteredData = data.filter((row) =>
      keys.some((key) => {
        const cell = row[key];

        return cell && cell.toString().toLowerCase().includes(value.toLowerCase());
      })
    );

    setDataToDisplay(filteredData);
  };

  return (
    <div>
      <Input.Search placeholder="input search text" allowClear onSearch={onSearch} style={{ width: '100%' }} />

      <br />
      <br />

      <Table
        size={'small'}
        pagination={false}
        scroll={{ y: 240 }}
        columns={_.keys(_.first(dataToDisplay))
          .filter((key) => !(hiddenColumns || []).includes(key))
          .map((key) => {
            return {
              width: key === '__toggle' ? 50 : undefined,
              title: { __toggle: '', active: 'Status' }[key] ?? key,
              dataIndex: key,
              key,
              // @ts-expect-error fix later
              sorter: (a, b) => (a[key] > b[key] ? 1 : -1),
              render: (value, record) => {
                if (key === 'active') {
                  return (
                    <Status
                      status={value === '+' ? StatusEnum.success : StatusEnum.error}
                      label={value === '+' ? 'Active' : 'Inactive'}
                    />
                  );
                }

                if (key === '__toggle') {
                  // @ts-expect-error fix later
                  return <Checkbox checked={value} onChange={() => onToggle(record)} />;
                }

                return value;
              },
            };
          })}
        // @ts-expect-error TODO: fix later
        dataSource={dataToDisplay}
      />

      <br />

      <Button onClick={submitToggled}>Submit</Button>
    </div>
  );
}
