export default ({ authorizedGET, authorizedPOST, authorizedPUT, authorizedDELETE }, endpoints) => {
  const getEndpoint = ({ sspId, dspId }) => {
    return (sspId ? endpoints.sspSellerInfo : endpoints.dspSellerInfo).replace(':id', sspId || dspId);
  };

  const getSellerInfo = ({ sspId, dspId }) => {
    return authorizedGET(getEndpoint({ sspId, dspId }));
  };

  const updateSellerInfo = ({ sspId, dspId }, body) => {
    return authorizedPUT(getEndpoint({ sspId, dspId }), body);
  };

  const createSellerInfo = ({ sspId, dspId }, body) => {
    return authorizedPOST(getEndpoint({ sspId, dspId }), body);
  };

  const deleteSellerInfo = ({ sspId, dspId }) => {
    return authorizedDELETE(getEndpoint({ sspId, dspId }));
  };

  return {
    getSellerInfo,
    updateSellerInfo,
    createSellerInfo,
    deleteSellerInfo,
  };
};
