import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { Select, Form, Button, Input, Checkbox, Popconfirm, notification } from 'antd';
import { createApiGateway } from '../../../../apiGateway';

const { Option } = Select;

function SellerInfo(props) {
  // eslint-disable-next-line react/prop-types
  const { sspId, dspId } = props;
  const apiGateway = createApiGateway();
  const [form] = Form.useForm();

  const [sellerInfo, setSellerInfo] = useState(null);

  const getSellerInfo = async () => {
    if (sspId || dspId) {
      const response = await apiGateway.sellerInfo.getSellerInfo({ sspId, dspId });

      setSellerInfo(_.get(response, 'data'));
    }
  };

  useEffect(() => {
    (async () => {
      await getSellerInfo();
    })();
  }, []);

  const onFinish = async (values) => {
    const getSellerInfoDTO = () => {
      const result = {
        ...values,
        passThrough: values.passThrough ? 1 : 0,
      };

      if (Array.isArray(result.servers)) {
        result.servers = result.servers.join(',') || null;
      }

      return result;
    };

    const sellerInfoDTO = getSellerInfoDTO();

    try {
      if (sellerInfo && sellerInfo.id) {
        await apiGateway.sellerInfo.updateSellerInfo({ sspId, dspId }, sellerInfoDTO);
      } else {
        await apiGateway.sellerInfo.createSellerInfo({ sspId, dspId }, sellerInfoDTO);
      }

      await getSellerInfo();

      notification.success({ message: 'OK' });
    } catch (e) {
      notification.error({ message: e.toString() });
    }
  };

  const deleteSellerInfo = async () => {
    try {
      await apiGateway.sellerInfo.deleteSellerInfo({ sspId, dspId });

      form.resetFields();

      notification.success({ message: 'OK' });
    } catch (e) {
      notification.error({ message: e.toString() });
    }
  };

  if (sellerInfo) {
    form.setFields([
      {
        name: 'sellerName',
        value: sellerInfo && sellerInfo.sellerName,
      },
      {
        name: 'sellerType',
        value: sellerInfo && sellerInfo.sellerType,
      },
      {
        name: 'sellerDomain',
        value: sellerInfo && sellerInfo.sellerDomain,
      },
      {
        name: 'passThrough',
        value: sellerInfo && sellerInfo.passThrough,
      },
      {
        name: 'comment',
        value: sellerInfo && sellerInfo.comment,
      },
      {
        name: 'servers',
        value: sellerInfo && sellerInfo.servers ? sellerInfo.servers.split(',') : [],
      },
    ]);
  }

  return (
    <div>
      <h3>RTB Seller Info</h3>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name="servers"
          label="Servers"
          extra={
            <div style={{ marginTop: '8px', fontSize: '12px', color: '#888' }}>
              The <code>sellers.json</code> file will be updated on the selected servers every 5 minutes. You can view
              the current <code>sellers.json</code> files here:
              <ul style={{ margin: 0, paddingLeft: '16px' }}>
                <li>
                  <a href="https://waardex.com/sellers.json" target="_blank" rel="noopener noreferrer">
                    waardex.com/sellers.json
                  </a>
                </li>
                <li>
                  <a href="http://www.crystall.io/sellers.json" target="_blank" rel="noopener noreferrer">
                    crystall.io/sellers.json
                  </a>
                </li>
              </ul>
            </div>
          }
        >
          <Select style={{ minWidth: 300 }} mode="multiple">
            <Option value="waardex.com">waardex.com</Option>
            <Option value="crystall.io">crystall.io</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="sellerType"
          label="Seller Type"
          rules={[{ required: true, message: 'Please select Seller Type' }]}
        >
          <Select style={{ width: 300 }}>
            <Option value="publisher">Publisher</Option>
            <Option value="intermediary">Intermediary</Option>
            <Option value="both">Both</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="sellerName"
          label="Seller Name"
          rules={[{ required: true, message: 'Please select Seller Name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="sellerDomain"
          label="Seller Domain"
          rules={[{ required: true, message: 'Please select Seller Domain' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="passThrough" valuePropName="checked">
          <Checkbox>Seller Passthrough</Checkbox>
        </Form.Item>

        <Form.Item name="comment" label="Comment">
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {sellerInfo && sellerInfo.id && (
                <Popconfirm
                  title="Are you sure delete this RTB Seller Info?"
                  onConfirm={deleteSellerInfo}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" style={{ marginLeft: 10 }}>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
            <div>
              <Button type="link">
                <a href={process.env.REACT_APP_API_URL + '/sellers.json'} target="_blank" rel="noreferrer">
                  Open sellers.json
                </a>
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default SellerInfo;
