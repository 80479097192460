import React, { useEffect, useState } from 'react';
import { Select, Form, Button, Modal, Input, Space, notification, Table } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { JsonView, defaultStyles } from 'react-json-view-lite';
import _, { omitBy, isNil, toNumber } from 'lodash';
import moment from 'moment';
import { createApiGateway } from '../../../../../apiGateway';
import { Spinner } from '../../../../../components';

const { Option } = Select;

const filterOperators = [
  { label: 'Equal', value: 'eq' },
  { label: 'Not equal', value: 'ne' },
  { label: 'Greater than', value: 'gt' },
  { label: 'Greater than equal', value: 'gte' },
  { label: 'Less than', value: 'lt' },
  { label: 'Less than equal', value: 'lte' },
];

export default function DataSampleTool() {
  const apiGateway = createApiGateway();

  const [jsonToShow, setJsonToShow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ssps, setSsps] = useState([]);
  const [dsps, setDsps] = useState([]);
  const [samples, setSamples] = useState([]);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const [sspList, dspList, samples] = await Promise.all([
        apiGateway.ssps.getAll(),
        apiGateway.dsps.getAll(),
        apiGateway.samples.list(),
      ]);

      setSamples(samples);
      setSsps(sspList.filter((x) => x.isActive));
      setDsps(dspList.filter((x) => x.isActive));

      setLoading(false);
    })();
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);

      if (values.filters) {
        values.filters = values.filters.map(({ field, operator, value }) => ({
          field,
          value: toNumber(value) || (value == 0 ? 0 : value),
          operator,
        }));
      } else {
        values.filters = [];
      }

      await apiGateway.statistics.getReqOrResStatistics(omitBy(values, isNil));

      notification.info({
        message: 'Sample will be available soon',
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);

      notification.error({
        message: 'Error fetching data. Please refresh the page and try again',
        description: e.isAxiosError ? JSON.stringify(e.response.data) : e.message,
      });
    }
  };

  const clearJsonToShow = () => {
    setJsonToShow(null);
  };

  const getJsonWindow = () => (
    <Modal
      title="JSON Object"
      visible
      width="100%"
      bodyStyle={{ overflowX: 'scroll' }}
      style={{ top: 20 }}
      onCancel={clearJsonToShow}
      onOk={clearJsonToShow}
    >
      <JsonView
        data={jsonToShow}
        style={{
          ...defaultStyles,
          noQuotesForStringValues: false,
          quotesForFieldNames: true,
        }}
      />
    </Modal>
  );

  const selectSearch = (inputValue, option) => {
    const searchValue = JSON.stringify(option.children);

    return searchValue.toLowerCase().trim().includes(inputValue.toLowerCase().trim());
  };

  const [form] = Form.useForm();

  if (loading || !form) return <Spinner loading />;

  return (
    <div>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name="type"
          label="Event"
          initialValue="sspBidRequest"
          rules={[{ required: true, message: 'Please select the event' }]}
        >
          <Select style={{ width: 300 }}>
            <Option value="fullFlow">Full Flow</Option>
            <Option value="sspBidRequest">SSP Bid Request</Option>
            <Option value="dspBidRequest">DSP Bid Request</Option>
            <Option value="dspBidResponse">DSP Bid Response</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="sspId"
          label="SSP"
          initialValue="any"
          rules={[{ required: true, message: 'Please select SSP' }]}
        >
          <Select style={{ width: 300 }} showSearch allowClear filterOption={selectSearch}>
            <Option value="any">Random</Option>
            <Option value="unknown">Unknown (Only for SSP Bid Request event)</Option>
            {ssps.map((x, i) => (
              <Option key={i} value={x.id}>
                {x.id} {x.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="dspId"
          label="DSP"
          initialValue="any"
          rules={[{ required: true, message: 'Please select DSP' }]}
        >
          <Select style={{ width: 300 }} showSearch allowClear filterOption={selectSearch}>
            <Option value="any">Random</Option>
            {dsps.map((x, i) => (
              <Option key={i} value={x.id}>
                {x.id} {x.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="timeoutMs"
          label="Timeout"
          initialValue={10_000}
          rules={[{ required: true, message: 'Please select the timeout' }]}
        >
          <Select
            style={{ width: 300 }}
            options={[
              { value: 5_000, label: '5 seconds' },
              { value: 10_000, label: '10 seconds' },
              { value: 30_000, label: '30 seconds' },
              { value: 60_000, label: '1 minute' },
              { value: 60_000 * 5, label: '5 minutes' },
              { value: 60_000 * 10, label: '10 minutes' },
              { value: 60_000 * 15, label: '15 minutes' },
              { value: 60_000 * 30, label: '30 minutes' },
              { value: 60_000 * 60, label: '1 hour' },
            ]}
          />
        </Form.Item>

        <Form.List name="filters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'field']}
                    label="Filter By (Field Path Here)"
                    style={{ minWidth: 300 }}
                  >
                    <Input placeholder="imp[0].bidfloor" />
                  </Form.Item>

                  <Form.Item {...restField} name={[name, 'operator']} label="Operator" style={{ minWidth: 300 }}>
                    <Select options={filterOperators} />
                  </Form.Item>

                  <Form.Item {...restField} name={[name, 'value']} label="Filter Value" style={{ minWidth: 300 }}>
                    <Input />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add filter
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Get Data Sample
          </Button>
        </Form.Item>
      </Form>

      <Table
        pagination={false}
        size="small"
        dataSource={_.sortBy(samples, (x) => x.key.replace('sample:', ''))}
        columns={[
          {
            title: 'Date Time',
            dataIndex: 'key',
            key: 'key',
            render: (value) => moment(value.replace('sample:', '')).format('YYYY-MM-DD HH:mm:SS'),
            sorter: (a, b) => moment(a.key.replace('sample:', '')) - moment(b.key.replace('sample:', '')),
          },
          {
            title: 'SSP',
            dataIndex: 'sspId',
            key: 'sspId',
            render: (value, record) => {
              const { sspId } = record.value.meta;

              if (!sspId) return '-';

              const ssp = ssps.find((x) => x.id === record.value.meta.sspId);

              return `[${sspId}] ${ssp.name}`;
            },
          },
          {
            title: 'DSP',
            dataIndex: 'dspId',
            key: 'dspId',
            render: (value, record) => {
              const { dspId } = record.value.meta;

              if (!dspId) return '-';

              const dsp = dsps.find((x) => x.id === record.value.meta.dspId);

              return `[${dspId}] ${dsp.name}`;
            },
          },
          {
            title: 'Node IP',
            dataIndex: 'nodeIp',
            key: 'nodeIp',
            render: (value, record) => record.value.meta.nodeIp || '-',
          },
          {
            title: 'Sample',
            dataIndex: 'value',
            key: 'value',
            render: (value) => (
              <Button type="text" onClick={() => setJsonToShow(value)}>
                Open Sample
              </Button>
            ),
          },
        ]}
      />

      {jsonToShow ? getJsonWindow() : ''}
    </div>
  );
}
